<template>
    <div>
        <ts-page-title
            :title="$t('productCategory.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('productCategory.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between tw-items-center">
                    <div>
                        <a-button type="primary" @click="addNew"
                            v-if="$can('create-product-categories')"
                        >
                            {{ $t("addNew") }}
                        </a-button>
                    </div>
                    <div class="tw-space-x-3">
                        <a-tooltip :title="$t('productCategory.search')">
                            <a-input-search
                                v-model="search"
                                :placeholder="$t('productCategory.search')"
                                style="width: 200px"
                            />
                        </a-tooltip>
                    </div>
                </div>
            </ts-panel-wrapper>
            <div class="tw-overflow-x-scroll">
                <ts-table
                    :columns="columns"
                    :records="resources"
                    :loading="loading"
                >
                    <template v-slot="{ record }">
                        <td
                            :class="
                                record.is_deleted
                                    ? 'tw-line-through tw-text-red-600'
                                    : ''
                            "
                        >
                            {{ record.shop_name }}
                        </td>
                        <td
                            :class="
                                record.is_deleted
                                    ? 'tw-line-through tw-text-red-600'
                                    : ''
                            "
                        >
                            {{ record.category_code }}
                        </td>
                        <td
                            :class="
                                record.is_deleted
                                    ? 'tw-line-through tw-text-red-600'
                                    : ''
                            "
                        >
                            {{ record.category_name_kh }}
                        </td>
                        <td
                            :class="
                                record.is_deleted
                                    ? 'tw-line-through tw-text-red-600'
                                    : ''
                            "
                        >
                            {{ record.category_name_en }}
                        </td>
                        <td class="text-left tw-space-x-3">
													<template
														v-if="!record.is_deleted"
													>
														<a

																v-if="$can('delete-product-categories')"
																href="#"
																v-tooltip="$t('edit')"
																class="text-primary mr-2"
																@click.prevent="edit(record)"
														>
																<i class="fas fa-edit"></i>
														</a>
														<a-popconfirm
																v-if="
																		$can('delete-product-categories')
																"
																placement="leftTop"
																:title="$t('are_you_sure_to_delete')"
																:ok-text="$t('yes')"
																:cancel-text="$t('no')"
																@confirm="deleteRecord(record)"
														>
																<a href="#" :disabled="record._deleting">
																		<i
																				class="fas fa-circle-notch spin"
																				v-if="record._deleting"
																		></i>
																		<i class="fa fa-trash text-red" v-else></i>
																</a>
														</a-popconfirm>
													</template>
                        </td>
                    </template>
                </ts-table>
            </div>
            <div class="d-flex justify-content-end tw-p-4">
                <ts-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-pagination>
            </div>
        </ts-panel>

        <!-- form-action -->
        <a-modal
            v-if="showForm"
            v-model="showForm"
            :title="$t('productCategory.pageTitle')"
            centered
            :maskClosable="false"
            @cancel="clearEdit"
            :zIndex="1020"
            :footer="null"
        >
            <from-action @cancel="clearEdit" />
        </a-modal>
    </div>
</template>

<script>
import { debounce } from "lodash";
import FromAction from "./form";
import { mapState } from "vuex";

export default {
    name: "bookingIndex",
    components: {
        FromAction
    },
    data() {
        return {
            showForm: false,
            loading: false,
            show_update_form: false
        };
    },
    computed: {
        ...mapState("inventory/productCategory", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.inventory.productCategory.search;
            },
            set(newValue) {
                this.$store.commit(
                    "inventory/productCategory/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "inventory/productCategory/RESET_CURRENT_PAGE"
                );
            }
        },
        columns() {
            return [
                { name: this.$t("productCategory.shopName") },
                { name: this.$t("productCategory.categoryCode") },
                { name: this.$t("productCategory.nameKh") },
                { name: this.$t("productCategory.nameEn") },
                {
                    name: this.$t("actions"),
                    style: "text-align:center; width: 1px;"
                }
            ];
        }
    },
    methods: {
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("inventory/productCategory/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                });
        },
        addNew() {
            this.showForm = true;
        },
        clearEdit() {
            this.$store.commit("inventory/productCategory/SET_EDIT_DATA", {});
            this.fetchData();
            this.showForm = false;
        },
        edit(record) {
            this.$store.commit(
                "inventory/productCategory/SET_EDIT_DATA",
                record
            );
            this.showForm = true;
        },
        deleteRecord(record) {
            record._deleting = true;
            this.$store
                .dispatch(
                    "inventory/productCategory/destroy",
                    record.category_id
                )
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.fetchData();
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    record._deleting = false;
                });
        }
    },
    watch: {
        search: debounce(function() {
            this.fetchData();
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("inventory/productCategory/RESET_STATE");
        next();
    }
};
</script>
